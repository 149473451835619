/* eslint-disable react/no-unknown-property */
import React, { useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { PlanetData } from '../StarSystemContext';
import { useSound } from '../../../utils';

interface PlanetProps {
  planet: PlanetData;
}

export const Planet = ({
  planet: { color, radius, size, period, sound, planet },
}: PlanetProps) => {
  const planetRef = useRef<THREE.Mesh>(null);
  const { playNote } = useSound();
  const [sounded, setSounded] = useState(false);

  useFrame(({ clock }) => {
    const t = clock.getElapsedTime();
    const x = (radius * 15 + 5) * Math.sin(t * (1 / period));
    const z = (radius * 15 + 5) * Math.cos(t * (1 / period));

    const direction = Math.floor(Math.sin(t * (1 / period)));

    if (direction === 0 && z > 0 && !sounded) {
      console.log('ding!', planet, direction, z);
      playNote(sound);
      setSounded(true);
    }
    if (direction === 0 && z < 0 && sounded) {
      setSounded(false);
    }

    if (planetRef.current) {
      planetRef.current.position.x = x;
      planetRef.current.position.z = z;
    }
  });

  return (
    <>
      <mesh ref={planetRef}>
        <sphereGeometry args={[size, 32, 32]} />
        <meshStandardMaterial color={color} />
      </mesh>
      {/* <Ecliptic xRadius={radius * 15 + 5} zRadius={radius * 15 + 5} /> */}
    </>
  );
};
