import React, { useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
  ICapiPanelAction,
  ModalRoot,
  CapiPanel,
  isSafari,
  NotificationArea,
} from 'asu-sim-toolkit';

import { useStores } from '../../providers/store/use-stores';
import { getCapi } from '../../capi';
import { Icon } from '../Icon';
import { NotificationItem } from '../NotificationItem/NotificationItem';
import { isDebugMode } from '../../config';
import { versionHistory } from '../../version-history';
import OrbitView from '../Animation/OrbitView/OrbitView';
import TopView from '../Animation/TopView/TopView';
import { StarSystemProvider } from '../Animation/StarSystemContext';
import { Stars } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';

export const MainView = observer(() => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const {
    modalStore,
    notificationStore,
    recapStore: { showRecap, hasNewUpdates },
    fullscreenStore: {
      registerFullscreenElement,
      toggleFullscreen,
      fullscreenControlState,
      isFullscreen,
    },
    simContextStore: { forceSimMode, mode },
  } = useStores();

  useEffect(() => {
    if (!wrapperRef.current) return;

    showRecap();
    registerFullscreenElement(wrapperRef.current);
  }, [registerFullscreenElement, showRecap]);

  const debugActions: ICapiPanelAction[] = useMemo(
    () => {
      const actions: ICapiPanelAction[] = [];

      if (versionHistory.length > 0) {
        actions.push({
          iconId: hasNewUpdates ? 'newUpdates' : 'info',
          tooltip: 'Version history',
          onClick: () => showRecap(false),
        });
      }

      return actions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasNewUpdates]
  );

  return (
    <div ref={wrapperRef} className="bg-black">
      <Canvas className="!absolute h-screen w-screen">
        <Stars count={3000} fade={false} depth={400} />
      </Canvas>
      <NotificationArea
        notificationStore={notificationStore}
        placement="top-center"
        itemComponent={NotificationItem}
      />
      <ModalRoot modalStore={modalStore}>
        <div className="h-screen w-screen overflow-hidden flex">
          {(isDebugMode || mode === 'AUTHOR') && (
            <CapiPanel
              additionalActions={debugActions}
              capi={getCapi()}
              onSimModeChange={forceSimMode}
            />
          )}
          <div className="relative h-full w-screen overflow-hidden flex items-center justify-center flex-col">
            <StarSystemProvider>
              <OrbitView />
              <TopView />
            </StarSystemProvider>
          </div>
          {fullscreenControlState.isVisible && !isSafari && (
            <button
              className="absolute bg-white w-[40px] h-[40px] shadow-outer rounded-md right-md bottom-md z-50 border border-gray-100"
              disabled={!fullscreenControlState.isEnabled}
              onClick={toggleFullscreen}
            >
              <Icon id={isFullscreen ? 'fullscreen_exit' : 'fullscreen'} />
            </button>
          )}
        </div>
      </ModalRoot>
    </div>
  );
});
