export enum CapiSimMode {
  ToneMatch = 'toneMatch',
  GraphMatch = 'graphMatch',
  MeasureDistance = 'measureDistance',
  StarMass = 'starMass',
}

export enum CapiPlanet {
  Mercury = 'Mercury',
  Venus = 'Venus',
  Earth = 'Earth',
  Mars = 'Mars',
  Jupiter = 'Jupiter',
  Saturn = 'Saturn',
  Uranus = 'Uranus',
  Neptune = 'Neptune',
  ExoP = 'ExoP',
  ExoQ = 'ExoQ',
  ExoR = 'ExoR',
  ExoS = 'ExoS',
}

export enum CapiPlanetGraph {
  GraphMercury = 'graphMercury',
  GraphVenus = 'graphVenus',
  GraphEarth = 'graphEarth',
  GraphMars = 'graphMars',
  GraphJupiter = 'graphJupiter',
  GraphSaturn = 'graphSaturn',
  GraphUranus = 'graphUranus',
  GraphNeptune = 'graphNeptune',
  GraphExoP = 'graphExoP',
  GraphExoQ = 'graphExoQ',
  GraphExoR = 'graphExoR',
  GraphExoS = 'graphExoS',
}

export type CapiPlanetArray = (keyof typeof CapiPlanet)[];

export const TONE_MATCH = {
  id: 'toneMatch',
  min: 0,
  max: 2,
  step: 0.1,
  defaultValue: 1.3,
};

export const MEASURE_PERIOD = {
  id: 'measurePeriod',
  min: 0.1,
  max: 165,
  step: 0.1,
  defaultValue: 1,
};

export const STAR_MASS = {
  id: 'starMass',
  min: 0.1,
  max: 25,
  step: 0.1,
  defaultValue: 1,
};
