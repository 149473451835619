import {
  CAPI,
  CAPI_TYPES,
  FullscreenCapiSchema,
  GodModeCapiSchema,
  IFullscreenCapiModel,
  IGodModeCapiModel,
  TCapiSchema,
} from 'asu-sim-toolkit';

import { isDebugMode } from './config';
import {
  CapiPlanet,
  CapiPlanetArray,
  CapiPlanetGraph,
  CapiSimMode,
  MEASURE_PERIOD,
  TONE_MATCH,
} from './stores/domain';

export interface ICapiModel extends IFullscreenCapiModel, IGodModeCapiModel {
  'Sim.mode': CapiSimMode;
  'Sim.planetEnabled': CapiPlanetArray;
  'Sim.audioEnabled': CapiPlanetArray;
  'Sim.indicator': CapiPlanet;
  'Sim.audio.mute': boolean;

  'Sim.toneMatchSlider.enabled': boolean;
  'Sim.toneMatchSlider.visible': boolean;
  'Sim.toneMatchSlider.moveCount': number;
  'Sim.toneMatchSlider.value': number;

  'Sim.graphMatch.matchEnabled': boolean;
  'Sim.graphMatch.selectedPlanet': CapiPlanet;
  'Sim.graphMatch.visible': CapiPlanetGraph;
  'Sim.graphMatch.enabled': CapiPlanetGraph;
  'Sim.graphMatch.showLabel': CapiPlanetGraph;

  'Sim.graphMatch.graphMercury.planet': CapiPlanet;
  'Sim.graphMatch.graphVenus.planet': CapiPlanet;
  'Sim.graphMatch.graphEarth.planet': CapiPlanet;
  'Sim.graphMatch.graphMars.planet': CapiPlanet;
  'Sim.graphMatch.graphJupiter.planet': CapiPlanet;
  'Sim.graphMatch.graphSaturn.planet': CapiPlanet;
  'Sim.graphMatch.graphUranus.planet': CapiPlanet;
  'Sim.graphMatch.graphNeptune.planet': CapiPlanet;
  'Sim.graphMatch.graphExoP.planet': CapiPlanet;
  'Sim.graphMatch.graphExoQ.planet': CapiPlanet;
  'Sim.graphMatch.graphExoR.planet': CapiPlanet;
  'Sim.graphMatch.graphExoS.planet': CapiPlanet;

  'Sim.graphMatch.play': boolean;
  'Sim.graphMatch.pause': boolean;
  'Sim.graphMatch.replay': boolean;

  'Sim.measurePeriod.enabled': boolean;
  'Sim.measurePeriod.value': number;

  'Sim.starMass.enabled': boolean;
  'Sim.starMass.value': number;
}

export const capiSchema: TCapiSchema<ICapiModel> = {
  ...GodModeCapiSchema,
  ...FullscreenCapiSchema,
  'Sim.mode': {
    initialValue: CapiSimMode.ToneMatch,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiSimMode),
    },
  },
  'Sim.planetEnabled': {
    initialValue: [],
    options: {
      type: CAPI_TYPES.ARRAY,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.audioEnabled': {
    initialValue: [],
    options: {
      type: CAPI_TYPES.ARRAY,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.indicator': {
    initialValue: undefined,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.audio.mute': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },

  'Sim.toneMatchSlider.enabled': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.toneMatchSlider.visible': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.toneMatchSlider.moveCount': {
    initialValue: 0,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },
  'Sim.toneMatchSlider.value': {
    initialValue: TONE_MATCH.defaultValue,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },

  'Sim.graphMatch.matchEnabled': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.graphMatch.selectedPlanet': {
    initialValue: undefined,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiPlanetGraph),
    },
  },
  'Sim.graphMatch.visible': {
    initialValue: [],
    options: {
      type: CAPI_TYPES.ARRAY,
      allowedValues: Object.values(CapiPlanetGraph),
    },
  },
  'Sim.graphMatch.enabled': {
    initialValue: [],
    options: {
      type: CAPI_TYPES.ARRAY,
      allowedValues: Object.values(CapiPlanetGraph),
    },
  },
  'Sim.graphMatch.showLabel': {
    initialValue: [],
    options: {
      type: CAPI_TYPES.ARRAY,
      allowedValues: Object.values(CapiPlanetGraph),
    },
  },
  'Sim.graphMatch.graphMercury.planet': {
    initialValue: undefined,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.graphMatch.graphVenus.planet': {
    initialValue: undefined,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.graphMatch.graphEarth.planet': {
    initialValue: undefined,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.graphMatch.graphMars.planet': {
    initialValue: undefined,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.graphMatch.graphJupiter.planet': {
    initialValue: undefined,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.graphMatch.graphSaturn.planet': {
    initialValue: undefined,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.graphMatch.graphUranus.planet': {
    initialValue: undefined,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.graphMatch.graphNeptune.planet': {
    initialValue: undefined,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.graphMatch.graphExoP.planet': {
    initialValue: undefined,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.graphMatch.graphExoQ.planet': {
    initialValue: undefined,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.graphMatch.graphExoR.planet': {
    initialValue: undefined,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.graphMatch.graphExoS.planet': {
    initialValue: undefined,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiPlanet),
    },
  },
  'Sim.graphMatch.play': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.graphMatch.pause': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.graphMatch.replay': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },

  'Sim.measurePeriod.enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.measurePeriod.value': {
    initialValue: MEASURE_PERIOD.defaultValue,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },

  'Sim.starMass.enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.starMass.value': {
    initialValue: MEASURE_PERIOD.defaultValue,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },
};

let capi: CAPI<ICapiModel>;

export function getCapi() {
  if (!capi) {
    capi = new CAPI<ICapiModel>(isDebugMode);
  }
  return capi;
}
