import React, { useEffect, useState } from 'react';
import { useStarSystem } from '../StarSystemContext';
import { calculateKeplerPeriod } from '../../../utils';

const GLOBAL_SCALE_FACTOR = 1.15;
const BASE_WIDTH = 1920;
const BASE_HEIGHT = 400;
const SVG_WIDTH = BASE_WIDTH * GLOBAL_SCALE_FACTOR;
const SVG_HEIGHT = BASE_HEIGHT * GLOBAL_SCALE_FACTOR;
const CENTER_X = SVG_WIDTH / 2;
const CENTER_Y = SVG_HEIGHT / 2;
const STAR_SIZE_SCALE = 1 * GLOBAL_SCALE_FACTOR;
const SLIDER_WIDTH = 150 * GLOBAL_SCALE_FACTOR;

const radiusMap: { [key: number]: number } = {
  0: 16 * GLOBAL_SCALE_FACTOR,
  1: 86 * GLOBAL_SCALE_FACTOR,
  2: 157 * GLOBAL_SCALE_FACTOR,
};

const getRadiusForAU = (au: number): number => {
  if (au <= 0) return radiusMap[0];
  if (au >= 2) return radiusMap[2];

  const lowerAU = Math.floor(au);
  const upperAU = Math.ceil(au);

  const lowerRadius = radiusMap[lowerAU];
  const upperRadius = radiusMap[upperAU];

  return lowerRadius + (upperRadius - lowerRadius) * (au - lowerAU);
};

const TopView = () => {
  const { setTestObjectRadius } = useStarSystem();
  const { star } = useStarSystem();
  const [distanceAU, setDistanceAU] = useState<number>(1);

  const testObjectRadius = getRadiusForAU(distanceAU);

  const handleDistanceChange = (value: number) => {
    setDistanceAU(value);
    setTestObjectRadius(value);
  };

  return (
    <div className="flex flex-1 w-full justify-center items-center">
      <div className="flex flex-col items-center">
        <svg
          width={SVG_WIDTH}
          height={SVG_HEIGHT}
          style={{ position: 'relative' }}
        >
          <circle
            cx={CENTER_X}
            cy={CENTER_Y}
            r={star.radius * STAR_SIZE_SCALE}
            fill="yellow"
          />

          <circle
            cx={CENTER_X}
            cy={CENTER_Y}
            r={star.radius * STAR_SIZE_SCALE * 3}
            fill="url(#starGlow)"
          />
          <defs>
            <radialGradient id="starGlow">
              <stop offset="0.18" stopColor="#FEDA88" />
              <stop offset="1" stopColor="#BD3C0B" stopOpacity="0" />
            </radialGradient>
          </defs>

          <TestObject radius={testObjectRadius} au={distanceAU} />
          <foreignObject
            x={CENTER_X - 15}
            y={CENTER_Y + testObjectRadius + 5}
            width="30"
            height="40"
          >
            <img src="/assets/soundIndicator.svg" />
          </foreignObject>
          <foreignObject
            x={CENTER_X + 20 * GLOBAL_SCALE_FACTOR}
            y={CENTER_Y - 16}
            width={SLIDER_WIDTH + 80}
            height="32"
          >
            <div className="flex items-center justify-between">
              <input
                type="range"
                min="0.1"
                max="2"
                step="0.1"
                value={distanceAU}
                onChange={(e) =>
                  handleDistanceChange(parseFloat(e.target.value))
                }
                className={`position-relative h-1 bg-gray-200 rounded-lg cursor-pointer appearance-none`}
                style={{
                  width: SLIDER_WIDTH,
                }}
              />
              <input
                type="number"
                min="0.1"
                max="2"
                step="0.1"
                value={distanceAU}
                onChange={(e) =>
                  handleDistanceChange(parseFloat(e.target.value))
                }
                className="w-12 h-8 px-2 border border-gray-300 rounded-lg"
              />
              <span className="text-white">au</span>
            </div>
          </foreignObject>
        </svg>
      </div>
    </div>
  );
};

type TestObjectProps = {
  radius: number;
  au: number;
};

const TestObject = ({ radius, au }: TestObjectProps) => {
  const [elapsedTime, setElapsedTime] = useState<number>(0);

  const keplerPeriod = calculateKeplerPeriod(au);

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 0.016);
    }, 16);

    return () => clearInterval(interval);
  }, []);

  // calculating the test object position based on trigonometric functions in a two-dimensional coord system
  const planetX =
    CENTER_X + (radius + 6) * Math.sin(elapsedTime * (1 / keplerPeriod));
  const planetY =
    CENTER_Y + (radius + 6) * Math.cos(elapsedTime * (1 / keplerPeriod));

  return (
    <>
      <ellipse
        cx={CENTER_X}
        cy={CENTER_Y}
        rx={radius + 6}
        ry={radius + 6}
        stroke="grey"
        fill="none"
        strokeDasharray={10}
      />
      <circle
        cx={planetX}
        cy={planetY}
        r={10 * GLOBAL_SCALE_FACTOR}
        fill={'lightblue'}
      />
    </>
  );
};

export default TopView;
