export const planets = [
  {
    id: 0,
    planet: 'Mercury',
    color: 'rgb(139, 136, 120)',
    radius: 0.383,
    size: 0.383,
    period: 0.241,
    sound: 'D4',
  },
  {
    id: 1,
    planet: 'Venus',
    color: 'rgb(205, 127, 50)',
    radius: 0.949,
    size: 0.949,
    period: 0.615,
    sound: 'E4',
  },
  {
    id: 2,
    planet: 'Earth',
    color: 'rgb(70, 130, 180)',
    radius: 1.0,
    size: 1.0,
    period: 1.0,
    sound: 'G4',
  },
  {
    id: 3,
    planet: 'Mars',
    color: 'rgb(188, 39, 50)',
    radius: 0.532,
    size: 0.532,
    period: 1.88,
    sound: 'B4',
  },
];
