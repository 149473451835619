/* eslint-disable react/no-unknown-property */
import React, { useRef, useState, useEffect } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three';

interface SVGImageProps {
  ding: boolean;
  setDing: (ding: boolean) => void;
}

const SVGImage = ({ ding, setDing }: SVGImageProps) => {
  const texture = useLoader(
    THREE.TextureLoader,
    '/assets/topSoundIndicator.svg'
  );
  const ref = useRef();
  const [position, setPosition] = useState(2.6);
  const [opacity, setOpacity] = useState(0);
  const fadeOutSpeed = 0.016;
  const moveUpSpeed = 0.008;

  useEffect(() => {
    if (ding) {
      setPosition(2.6);
      setOpacity(1);
      setDing(false);
    }
  }, [ding, setDing]);

  useFrame(() => {
    if (opacity > 0) {
      setPosition((prev) => prev + moveUpSpeed);
      setOpacity((prev) => Math.max(prev - fadeOutSpeed, 0));
    }
  });

  return (
    <mesh ref={ref} position={[0, position, 30]}>
      <planeGeometry args={[2.5, 2.5]} />
      <meshStandardMaterial map={texture} transparent opacity={opacity} />
    </mesh>
  );
};

export default SVGImage;
