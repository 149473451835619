/* eslint-disable react/no-unknown-property */
import React from 'react';

export const Star = () => {
  return (
    <mesh>
      <sphereGeometry args={[4, 60, 60]} />
      <meshStandardMaterial
        color="#edb243"
        emissive="#edb243"
        emissiveIntensity={1}
      />
      <pointLight color="#edb243" intensity={2000} distance={2000} decay={2} />
    </mesh>
  );
};
