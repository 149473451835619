import { makeObservable, observable } from 'mobx';

import { ICapiModel } from '../capi';
import { IAppStore, IRootStore } from './types';
import { CapiBoundStore, ICAPI } from 'asu-sim-toolkit';
import { CapiSimMode } from './domain';

export class AppStore extends CapiBoundStore<ICapiModel> implements IAppStore {
  private readonly rootStore: IRootStore;
  mode = CapiSimMode.ToneMatch;
  masterMute = false;

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);

    this.rootStore = rootStore;

    makeObservable(this, {
      mode: observable,
      masterMute: observable,
    });

    this.bindToCapi('mode', 'Sim.mode');
    this.bindToCapi('masterMute', 'Sim.audio.mute');
  }
}
