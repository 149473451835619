/* eslint-disable react/no-unknown-property */
import React, { useRef, useState } from 'react';
import { useStarSystem } from '../StarSystemContext';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { calculateKeplerPeriod, useSound } from '../../../utils';
import { TriangleIndicator } from './Indicators';
import SVGImage from './SVGImage';

export const TestObject = () => {
  const { testObjectRadius } = useStarSystem();
  const cubeRef = useRef<THREE.Mesh>(null);
  const materialRef = useRef<THREE.Material>(null);
  const triangleRef = useRef<THREE.Mesh>(null);
  const [sounded, setSounded] = useState(false);
  const [ding, setDing] = useState(false);
  const { playNote } = useSound();

  useFrame(({ clock }) => {
    const t = clock.getElapsedTime();

    if (cubeRef.current) {
      cubeRef.current.rotation.x += 0.01;
      cubeRef.current.rotation.y += 0.01;
      const radius = testObjectRadius * 15 + 5;
      const period = calculateKeplerPeriod(
        Math.round(testObjectRadius * 10) / 10
      );
      const x = radius * Math.sin(t * (1 / period));
      const z = radius * Math.cos(t * (1 / period));
      cubeRef.current.position.set(x, 0, z);
      const direction = Math.floor(Math.sin(t * (1 / period)));

      if (direction === 0 && z > 0 && !sounded) {
        console.log('ding!', 'TestObject', direction, z);
        playNote('G3');
        setSounded(true);
        setDing(true);
      }
      if (direction === 0 && z < 0 && sounded) {
        setSounded(false);
      }
    }

    if (materialRef.current) {
      materialRef.current.emissiveIntensity = 0.5 + 0.5 * Math.sin(t * 5);
    }

    if (triangleRef.current && cubeRef.current) {
      const verticalOffset = 0.5 * Math.sin(t * 3);
      triangleRef.current.position.set(
        cubeRef.current.position.x,
        cubeRef.current.position.y + 3 + verticalOffset,
        cubeRef.current.position.z
      );
    }
  });

  return (
    <>
      <mesh ref={cubeRef}>
        <boxGeometry args={[1.5, 1.5, 1.5]} />
        <meshStandardMaterial
          ref={materialRef}
          color="lightblue"
          emissive="lightblue"
        />
      </mesh>
      <TriangleIndicator ref={triangleRef} />
      <SVGImage ding={ding} setDing={setDing} />
    </>
  );
};
