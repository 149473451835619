import React, { createContext, useContext, useState, ReactNode } from 'react';
import * as Tone from 'tone';

interface SoundContextType {
  playNote: (frequency: string) => void;
  masterMute: () => void;
  isMuted: boolean;
}

const defaultValues: SoundContextType = {
  playNote: undefined!,
  masterMute: undefined!,
  isMuted: false,
};

const SoundContext = createContext<SoundContextType>(defaultValues);

export const SoundProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isMuted, setIsMuted] = useState<boolean>(true);

  const synth = new Tone.PolySynth().toDestination();

  Tone.Master.volume.value = -10;

  const playNote = (frequency: string) => {
    if (!isMuted) {
      synth.triggerAttackRelease(frequency, '8n');
    }
  };

  const masterMute = () => {
    setIsMuted((prev) => !prev);
  };

  return (
    <SoundContext.Provider value={{ playNote, masterMute, isMuted }}>
      {children}
    </SoundContext.Provider>
  );
};

export const useSound = (): SoundContextType => {
  const context = useContext(SoundContext);
  if (context === undefined) {
    throw new Error('useSound must be used within a SoundProvider');
  }
  return context;
};
