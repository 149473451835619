import React, { createContext, useContext, useState, ReactNode } from 'react';
import { planets } from '../../utils/planets';

export interface PlanetData {
  id: number;
  planet: string;
  color: string;
  radius: number;
  size: number;
  period: number;
  sound: string;
}

interface StarSystemContextType {
  star: { x: number; y: number; radius: number };
  planetRadius: number;
  planets: PlanetData[];
  testObjectRadius: number;
  setTestObjectRadius: (radius: number) => void;
}

const defaultValues: StarSystemContextType = {
  star: { x: 150, y: 150, radius: 20 },
  planetRadius: 100,
  planets: planets,
  testObjectRadius: 1,
  setTestObjectRadius: undefined!,
};

const StarSystemContext = createContext<StarSystemContextType>(defaultValues);

export const StarSystemProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [contextData, setContextData] =
    useState<StarSystemContextType>(defaultValues);

  const setTestObjectRadius = (radius: number) => {
    setContextData({ ...contextData, testObjectRadius: radius });
  };

  return (
    <StarSystemContext.Provider value={{ ...contextData, setTestObjectRadius }}>
      {children}
    </StarSystemContext.Provider>
  );
};

export const useStarSystem = (): StarSystemContextType =>
  useContext(StarSystemContext);
