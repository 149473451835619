/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { useStarSystem } from '../StarSystemContext';
import { Camera } from './Camera';
import { Star } from './Star';
import { Planet } from './Planet';
import { TestObject } from './TestObject';
import { useStores } from '../../../providers/store/use-stores';
import { observer } from 'mobx-react-lite';
import { useSound } from '../../../utils';

const OrbitView = observer(() => {
  const { planets } = useStarSystem();
  const { isMuted, masterMute } = useSound();

  const {
    appStore: { mode },
    fullscreenStore: { isFullscreen },
  } = useStores();

  console.log('mode', mode);
  console.log('isFullscreen', isFullscreen);

  const handleMuteClick = () => {
    masterMute();
  };

  return (
    <div className="flex flex-1 w-full border-b-2">
      <button
        onClick={handleMuteClick}
        className={`z-10 absolute left-md top-md cursor-pointer transition-colors duration-300 ease-in-out ${
          isMuted ? 'opacity-50' : 'opacity-100'
        }`}
      >
        <img
          src="assets/muteIcon.svg"
          alt="mute"
          className={`transition-opacity duration-300 ease-in-out ${
            isMuted ? 'opacity-50' : ''
          }`}
        />
      </button>

      <Canvas>
        <Camera />
        <Star />
        {planets.map((planet) => (
          <Planet planet={planet} key={planet.id} />
        ))}
        <TestObject />
        <ambientLight intensity={1} />
      </Canvas>
    </div>
  );
});

export default OrbitView;
